import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';
import Button from 'components/Button';

import { useScrollTo } from 'hooks/useScrollTo';

import { CampaignBannerBottomProps } from './models';

import './CampaignBannerBottom.scss';

const CampaignBannerBottom: FC<CampaignBannerBottomProps> = ({
  label,
  background,
  description,
  buttons,
  productImage,
}) => {
  const [handleScrollTo] = useScrollTo();

  return (
    <div className="campaignBannerBottom">
      <Image {...background.imageStructure} className="campaignBannerBottom__background" />
      <div className="campaignBannerBottom__content">
        <div className="content">
          <div className="campaignBannerBottom__image">
            <Image {...label.imageStructure} className="campaignBannerBottom__label" />
            <Image
              {...productImage.imageStructure}
              className="campaignBannerBottom__productImage"
            />
          </div>
          <DangerouslySetInnerHtml
            html={description}
            className="campaignBannerBottom__description"
          />
          <div className="campaignBannerBottom__buttons">
            {buttons?.map((button) => {
              const queryString = button.url?.includes('#') ? `#${button.url?.split('#')[1]}` : '';

              return (
                <Button
                  {...button}
                  key={button.ariaLabel}
                  className="campaignBannerBottom__button"
                  onClick={() => handleScrollTo(queryString)}
                >
                  {button?.label}
                </Button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignBannerBottom;
