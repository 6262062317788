import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import CampaignBannerBottom from 'components/CampaignBannerBottom/CampaignBannerBottom';
import CampaignContentBottom from 'components/CampaignContentBottom/CampaignContentBottom';
import CampaignContentTop from 'components/CampaignContentTop/CampaignContentTop';
import CampaignItemsWithBackground from 'components/CampaignItemsWithBackground';
import CampaignProductBanner from 'components/CampaignProductBanner/CampaignProductBanner';
import CampaignVideo from 'components/CampaignVideo/CampaignVideo';

import './CampaignProductPage.scss';

const CampaignProductPage: FC<CampaignProductPageTypes.CampaignProductPageProps> = ({
  data: {
    campaignProductPage: {
      seo,
      urls,
      color,
      campaignProductBanner,
      campaignContentTop,
      campaignVideo,
      campaignContentBottom,
      campaignItemsWithBackground,
      campaignBannerBottom,
    },
  },
}) => {
  const templateClasses = classNames('campaignProduct', color);

  const { title, keywords, description } = seo;

  return (
    <Layout className={templateClasses}>
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Seo {...{ urls, seo }} />
      <Container>
        <CampaignProductBanner {...campaignProductBanner[0]} />
        {campaignContentTop ? <CampaignContentTop {...campaignContentTop[0]} /> : null}
        {campaignVideo ? <CampaignVideo {...campaignVideo[0]} /> : null}
        {campaignContentBottom ? <CampaignContentBottom {...campaignContentBottom[0]} /> : null}
        {campaignItemsWithBackground ? (
          <CampaignItemsWithBackground {...campaignItemsWithBackground[0]} />
        ) : null}
        {campaignBannerBottom ? <CampaignBannerBottom {...campaignBannerBottom[0]} /> : null}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String]) {
    campaignProductPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      pageName
      color
      campaignProductBanner {
        ...CampaignProductBannerFragment
      }
      campaignContentTop {
        ...CampaignContentTopFragment
      }
      campaignVideo {
        ...CampaignVideoFragment
      }
      campaignContentBottom {
        ...CampaignContentBottomFragment
      }
      campaignItemsWithBackground {
        ...CampaignItemsWithBackgroundFragment
      }
      campaignBannerBottom {
        ...CampaignBannerBottomFragment
      }
    }
  }
`;

export default CampaignProductPage;
