import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { CampaignItemsWithBackgroundProps } from './models';

import './CampaignItemsWithBackground.scss';

const CampaignItemsWithBackground: FC<CampaignItemsWithBackgroundProps> = ({ image, items }) => (
  <div className="campaign-items-with-background">
    <div className="campaign-items-with-background__banner">
      <Image {...image.imageStructure} objectFit="contain" />
    </div>
    <div className="items">
      {items.map((item) => (
        <div className="campaign-items-with-background__item">
          <div className="campaign-items-with-background__image">
            <Image {...item.icon.imageStructure} objectFit="contain" />
          </div>

          <div className="campaign-items-with-background__button">{item.button}</div>

          {item.disclaimer ? (
            <div className="campaign-items-with-background__disclaimer">{item.disclaimer}</div>
          ) : null}

          <div className="campaign-items-with-background__description">
            <DangerouslySetInnerHtml html={item.description} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default CampaignItemsWithBackground;
